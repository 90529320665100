import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import "./LegalInformation.css";
import { Drains, LocalAttchments } from "../../../components/db";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
import { status_completed } from "../../FilterPage";

const LegalInformationModal = (props) => {
  const canvas = useRef();
  const [signatureNotEmpty, setSignatureNotEmpty] = useState(false);

  useEffect(() => {
    canvas.current._resizeCanvas();
    checkIfCanvasEmpty();
  }, [props.showModal]);

  const checkIfCanvasEmpty = () => {
    setSignatureNotEmpty(!canvas.current.isEmpty());
  };


  const defaultSignatureWidth = 600;

  const onAccept = async () => {
    const outputCanvas = getResizedCanvas(
      canvas.current.getCanvas(),
      defaultSignatureWidth,
      defaultSignatureWidth * 0.5
      );
      
      const signatureExists = await LocalAttchments.checkIfExists(props.formUuid,"mimeType","consent");
      if(signatureExists){
        const existingConsents = await LocalAttchments.getAttachmentsByOtherValue(props.formUuid, "mimeType", "consent");
        const consent = existingConsents[0]//.filter((x) => { return !x.deliveredFromFlow})[0];
        if(consent){
          console.log(consent)
          outputCanvas.toBlob(x => {
            LocalAttchments.updateAttachment(consent.uuid, {
              blob: x,
              name: t("task.consent"),
            }).then(async () => {
              Drains.updateRecord(props.formUuid, {
                emailRecipient: props.email,
                state: status_completed,
              }).then(y => {
                console.log("Email consent updated");
                props.toggleShow();
                props.printModal();
              });
            });
          })
        }
      }
      else{
        const fileUuid = uuidv4();
        const outputFileName = fileUuid + ".png";
        outputCanvas.toBlob((x) => {
          LocalAttchments.addAttachment({
            blob: x,
            formId: props.formUuid,
            name: t("task.consent"),
            fileName: outputFileName,
            mimeType: "image/png+consent",
            uuid: fileUuid,
            deliveredFromFlow: false
          }).then(() => {         
             Drains.updateRecord(props.formUuid, {
              emailRecipient: props.email,
              state: status_completed,
            }).then(y => {
              console.log("Email consent created")
              props.toggleShow();
              props.printModal();
            })
          });
        });
      }
  };

  const getResizedCanvas = (canvas, newWidth, newHeight) => {
    var tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    var ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };

  return (
    <MDBModal
      staticBackdrop
      show={props.showModal}
      setShow={props.toggleShow}
      tabIndex="-1"
      //onShow={() => {console.log("On Show")}}
    >
      <MDBModalDialog centered>
        <MDBModalContent scrollable className="modalWindow">
          <MDBModalHeader>
            <MDBModalTitle>Information</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <p>
              Ich willige ein, dass mir der Beleg zur Abfuhr von Sammelgruben
              und Kleinkläranlagen, die auch personenbezogene Daten im Sinne der
              EU-Datenschutzgrundverordnung (DSGVO) und des
              Bundesdatenschutzgesetzes (BDSG) enthalten, an meinen nachfolgende
              E-Mail-Adresse übersandt werden. Ich versichere, auf diese
              E-Mail-Adresse ungehindert vollen Zugriff zu haben. Ich werde
              unverzüglich darüber informieren, falls sich dies ändert oder ich
              eine andere Adresse nutzen sollte.
            </p>
            <br />
            <p>
              Bitte achten Sie darauf, keinen Dritten Zugriff auf Ihre E-Mails
              zu gewähren. Daher sollten Sie insbesondere keine E-Mail-Adresse
              verwenden, die von anderen empfangen wird oder die Sie gemeinsam
              mit anderen, etwa Familienmitgliedern gemeinsam benutzen. Ihnen
              ist bekannt, dass zwar der Versand an den Mailserver verschlüsselt
              erfolgt („Transportverschlüsselung“), der Inhalt der E-Mails
              jedoch grundsätzlich unverschlüsselt ist und mit der
              Datenübertragung über das Internet erhebliche Sicherheitsrisiken
              verbunden sind und daher nicht ausgeschlossen werden kann, dass
              sich andere Personen Zugriff auf den Inhalt unverschlüsselter
              E-Mails verschaffen.
            </p>
            <br />
            <p>
              Sie haben jederzeit die Möglichkeit, die Speicherung ihrer
              eMail-Adresse zu widerrufen. Hierzu senden Sie uns einfach eine
              eMail an info@azv-sude-schaale.de
            </p>
            <br />
            <p>
              Unsere vollständige Datenschutzerklärung finden Sie unter
              https://www.azv-sude-schaale.de/datenschutz
            </p>

            <div className="signature-container">
              <div className="signature-panel">
                <SignaturePad
                  penColor="black"
                  ref={canvas}
                  canvasProps={{ className: "signature-pad" }}
                  onEnd={checkIfCanvasEmpty}
                />
              </div>
            </div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn
              color="light"
              onClick={() => {
                props.setReturnPath("legalinfo")
                props.rejectEmail();
              }}
            >
              Ablehnen
            </MDBBtn>
            <MDBBtn
              className="bg-primaryDark"
              style={{ color: "white" }}
              color="light"
              disabled={!signatureNotEmpty}
              onClick={onAccept}
            >
              Akzeptieren
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default LegalInformationModal;
