import { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
import TaskInfoPage from "./Info/TaskInfoPage";
import DrainFormPage from "./DrainForm/DrainFormPage";
import { Link, useLocation } from "react-router-dom";
import ServiceFormPage from "./ServiceForm/ServiceFormPage";
import AttachmentsPage from "./Attachments/AttachmentsPage";
import { Drains, Services, LocalAttchments } from "../../components/db";
import "./TaskPage.css";
import Tools from "../../components/Tools";
import ValidateAndComplete from "./ValidateAndComplete";
import { toast } from "react-toastify";
import { t } from "i18next";
import { status_archived, status_completed } from "./../FilterPage";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import LegalInformationModal from "./EmailProcessing/LegalInformationModal";
import ConfirmRejectionModal from "./EmailProcessing/ConfirmRejectionModal";
import { PdfDrainConfrimation } from "../../components/PDF/PdfDrainConfirmation";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";

export const event_popup = "event_popup";
export const event_makeMandatory = "event_makeMandatory";
export const event_makeOptional = "event_makeOptional";

const TaskPage = (props) => {
  const search = useLocation().search;
  const uuid = new URLSearchParams(search).get("uuid");
  const type = new URLSearchParams(search).get("type");
  const tab = new URLSearchParams(search).get("tab");

  const [basicActive, setBasicActive] = useState("tab2");
  const [basicModal, setBasicModal] = useState(false);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [storedEmail, setStoredEmail] = useState("");
  const [emailDelivered, setEmailDelivered] = useState();
  const [loading, setLoading] = useState(false);
  const [completed, isCompleted] = useState();
  const [printModal, setPrintModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [customerPresent, setCustomerPresent] = useState(false);
  const [hasCoordinatesRecorded, setHasCoordinatesRecorded] = useState(false);
  const [status, setStatus] = useState();
  const [legalModal, toggleLegalModal] = useState(false);
  const [emailRejectModal, setEmailRejectModal] = useState(false);
  const [returnModal, setReturnModal] = useState();

  const legalModalRef = useRef();

  const navigate = useNavigate();

  const toggleShow = () => setBasicModal(!basicModal);
  const toggleShowPrintModal = () => setPrintModal(!printModal);
  const toggleSignatureModal = () => setSignatureModal(!signatureModal);

  const handleBasicClick = (value) => {
    if (value === "tab2" && !completed) {
      props.navbarProps.showCameraIcon();
    } else {
      props.navbarProps.hideCameraIcon();
    }

    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const validateForm = async () => {
    const cpltd = await ValidateAndComplete.Drain(data.uuid);
    if (cpltd) {
      setStatus(status_completed);
      toast.success(t("task.markedAsCompleted"), {
        onClose: () => {
          toggleShow();
          isCompleted(cpltd);
        },
      });
    }
  };

  const CreatePdfConfirmation = async () => {
    const pdfProtocol = await PdfDrainConfrimation.createPdf(uuid);
    await generateProtocol(pdfProtocol, uuid);
  };

  const validateSignature = async () => {
    await validateForm();
    if (customerPresent) {
      const signatureExists = await LocalAttchments.checkIfExists(
        uuid,
        "mimeType",
        "signature"
      );
      if (!signatureExists) {
        toggleSignatureModal();
        return;
      }
    }
  };

  const emailOnChange = async () => {
    if (data.type == "DRAIN") {
      Drains.updateRecord(data.uuid, {
        emailRecipient: email,
        state: status_completed,
      });
    } else {
      Services.updateRecord(data.uuid, {
        emailRecipient: value,
        state: status_completed,
      });
    }
  };

  const generateProtocol = async (protocolBlob, formUuid) => {
    const fileUuid = uuidv4();

    await LocalAttchments.removeAllCustomersProtocolsByUuid(formUuid);
    await LocalAttchments.addAttachment({
      blob: protocolBlob,
      formId: formUuid,
      name: "Kundenbeleg",
      mimeType: "application/pdf+protocol",
      uuid: fileUuid,
      fileName: fileUuid + ".pdf",
    });
  };

  const onRejectEmail = () => {
    toggleLegalModal(false);
    setEmailRejectModal(true);
  };

  const backToLegalInformation = () => {
    setEmailRejectModal(false);
    toggleLegalModal(true);
  };

  useEffect(() => {
    props.navbarProps.navbarTitle("Entleerungsauftrag");
    props.navbarProps.hideFilterIcon();
    const doAsync = async () => {
      try {
        setLoading(true);
        const data = await getData(uuid, type);
        setData(data);
        setStatus(data.state);
        setEmail(data.emailRecipient ?? "");
        setEmailDelivered(data.emailDelivered ?? "");
        setStoredEmail(data.emailRecipient ?? "");
        setLoading(false);
        setBackIcon();
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    doAsync();

    if (tab) {
      const newtab = "tab" + tab;
      setBasicActive(newtab);
    }

    console.log(legalModalRef);
  }, []);

  useEffect(() => {
    if (completed) {
      props.navbarProps.hideCameraIcon();
    } else {
      if (basicActive === "tab2") {
        props.navbarProps.showCameraIcon();
      } else {
        props.navbarProps.hideCameraIcon();
      }
    }

    const doAsync = async () => {
      const data = await getData(uuid, type);
      setStoredEmail(data.emailRecipient ?? "");
    };
    doAsync();
  }, [completed, basicActive]);

  const setBackIcon = () => {
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
    backIcon.onclick = onBackClick;
  };

  const restoreEmail = () => {
    console.log("Restore email to delivered: " + emailDelivered);
    setEmail(emailDelivered);
    setStoredEmail(emailDelivered);
  };

  const onBackClick = () => {
    navigate("/");
  };

  const removeConsents =  async () => {
    const consents = await LocalAttchments.getAttachmentsByOtherValue(data.uuid, "mimeType","consent");
    consents.map(async x => {
      await LocalAttchments.removeAttachment(x.uuid);
    })
  }

  if (loading) return <p>Loading...</p>;

  if (!data) return <p>Error in form loading</p>;

  return (
    <div style={{ padding: "10px" }}>
      <MDBTabs fill className="mb-3" style={{}}>
        <MDBTabsItem>
          <MDBTabsLink
            className=" tabBtn"
            onClick={() => handleBasicClick("tab1")}
            active={basicActive === "tab1"}
          >
            INFO
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            className="tabLink tabBtn"
            onClick={() => {
              handleBasicClick("tab2");
            }}
            active={basicActive === "tab2"}
          >
            EINGABEN
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            className="tabLink tabBtn"
            onClick={() => handleBasicClick("tab3")}
            active={basicActive === "tab3"}
          >
            ANHÄNGE
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === "tab1"}>
          <TaskInfoPage
            uuid={uuid}
            type={type}
            coordinatesRecorded={hasCoordinatesRecorded}
            status={status}
          />
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === "tab2"}>
          {type == "DRAIN" ? (
            <DrainFormPage
              uuid={uuid}
              type="DRAIN"
              confirmModal={validateSignature}
              showCameraIcon={props.showCameraIcon}
              setCompleted={isCompleted}
              completed={completed}
              setCustomerPresent={setCustomerPresent}
              setCoordinatesRecorded={setHasCoordinatesRecorded}
              setStatus={setStatus}
            />
          ) : (
            <ServiceFormPage
              uuid={uuid}
              type="SERVICE"
              confirmModal={toggleShow}
              showCameraIcon={props.showCameraIcon}
            />
          )}
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === "tab3"}>
          <AttachmentsPage
            uuid={uuid}
            type={type}
            completed={completed}
            archived={data.state != status_archived}
          />
        </MDBTabsPane>
      </MDBTabsContent>

      {/* Email Modal */}
      <MDBModal
        staticBackdrop
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
      >
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>
                Überprüfen Sie die angegebene E-Mail-Adresse
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                label="Email address"
                type="text"
                value={email}
                //defaultValue={data.emailRecipient ?? ""}
                onChange={(ev) => setEmail(ev.target.value)}
                contrast={Tools.detectDarkTheme()}
              />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                disabled={
                  storedEmail.length == 0
                }
                color="light"
                onClick={() => {
                  setEmail("");
                }}
              >
                löschen
              </MDBBtn>
              <MDBBtn
                className="bg-primaryDark"
                onClick={() => {
                  if (!validator.isEmail(email) && email && email.length > 0) {
                    toast.error(
                      "Fehler: Bitte prüfen Sie die E-Mail-Addresse."
                    );
                    return;
                  }

                  toggleShow();
                  if (email !== storedEmail) {
                    if(email.length === 0){
                      Drains.updateRecord(data.uuid, {
                        emailDelivered: "",
                        emailRecipient: "",
                        state: status_completed
                      }).then(() => {
                        removeConsents();
                        toggleShowPrintModal();
                      })
                    }
                    else{
                      toggleLegalModal(true);
                    }
                  } else {
                    toggleShowPrintModal();
                  }
                }}
              >
                speichern
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={printModal} setShow={setPrintModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>Frage</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowPrintModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Beleg drucken?</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="light"
                style={{ color: "white" }}
                className="bg-primaryDark"
                onClick={async () => {
                  navigate("/task/previewpdf?uuid=" + uuid);
                  await CreatePdfConfirmation();
                  isCompleted(false);
                  isCompleted(true);
                }}
              >
                Ja
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={async () => {
                  toggleShowPrintModal();
                  await CreatePdfConfirmation();
                  isCompleted(false);
                  isCompleted(true);
                }}
              >
                Nein
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Signature confirmation modal */}
      <MDBModal show={signatureModal} setShow={setSignatureModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>{t("warning")}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{t("task.noSignatureModal.question")}</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="light"
                onClick={() => {
                  //window.location.href = "/task/signature?uuid="+props.uuid
                  navigate("/task/signature?uuid=" + uuid);
                }}
              >
                {t("signature")}
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  toggleSignatureModal();
                  validateForm();
                }}
              >
                {t("task.noSignatureModal.saveAnyway")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <LegalInformationModal
        showModal={legalModal}
        toggleShow={toggleLegalModal}
        rejectEmail={onRejectEmail}
        formUuid={uuid}
        printModal={toggleShowPrintModal}
        email={email}
        setReturnPath={setReturnModal}
      />
      <ConfirmRejectionModal
        showModal={emailRejectModal}
        toggleShow={setEmailRejectModal}
        backToLegalInformation={backToLegalInformation}
        formUuid={uuid}
        printModal={toggleShowPrintModal}
        restoreEmail={restoreEmail}
        returnPath={returnModal}
        showEmailModal={toggleShow}
      />
      {/* <MDBBtn label="Show legal modal" onClick={() => {toggleLegalModal(!legalModal)}} /> */}
    </div>
  );
};

export default TaskPage;

const getData = async (uuid, type) => {
  var data = null;

  if (type.toLowerCase() == "service") {
    data = await Services.getByUuid(uuid);
  } else {
    data = await Drains.getByUuid(uuid);
  }
  return data;
};
