import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Drains, LocalAttchments } from "../../../components/db";
import { status_completed } from "../../FilterPage";

const ConfirmRejectionModal = (props) => {
  const [order, setOrder] = useState();

  useEffect(() => {
    const doAsync = async () => {
      const dbOrder = await Drains.getByUuid(props.formUuid);
      if (dbOrder) {
        setOrder(dbOrder);
      }
    };
    doAsync();
  }, []);

  const updateOrder = async () => {
    props.restoreEmail();
    Drains.updateRecord(props.formUuid, {
      emailRecipient: order.emailDelivered,
      state: status_completed
    });

    const attachment = await LocalAttchments.getAttachmentsByOtherValue(
      props.formUuid,
      "mimeType",
      "consent"
    );
    attachment.map((x) => {
      if (!x.deliveredFromFlow) {
        LocalAttchments.removeAttachment(x.uuid);
      }
    });

    props.toggleShow();
    props.printModal();
  };

  return (
    <MDBModal
      staticBackdrop
      show={props.showModal}
      setShow={props.toggleModal}
      tabIndex="-1"
    >
      <MDBModalDialog centered>
        <MDBModalContent scrollable className="modalWindow">
          <MDBModalHeader>
            <MDBModalTitle>Bist du sicher?</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            Die eingegebene E-Mail-Adresse wird nicht überommen. Wurde zuvor
            bereits die Einwilligung für eine andere E-Mail-Adresse erteilt, so
            wird der Kundenbeleg an diese versendet.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="light" onClick={() => {
              if(props.returnPath == "email"){
                props.toggleShow();
                props.showEmailModal();
              }
              else{
                props.backToLegalInformation();
              }
            }}>
              Abbrechen
            </MDBBtn>
            <MDBBtn className="bg-primaryDark" onClick={updateOrder}>
              OK
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ConfirmRejectionModal;
