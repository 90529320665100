import Dexie from "dexie";
import { status_completed, status_started } from "../pages/FilterPage";

export const db = new Dexie("util.app");

//schemas
db.version(1.4).stores({
  attachment: "id++, uuid, reference, name, type, path, formUuid",
  destination: "id++, uuid, valid, label, sort",
  drain:
    "id++, uuid, type, state, reference, number, scheduleFrom, scheduleTo, info, remark, date, check, longitude, latitude, longitudeRecorded, latitudeRecorded," +
    "emailRecipient, emailStatus, pitNumber, length, tonnage, width, pitStreet, pitStreetNumber, pitPostCode, pitCity, pin, salutation, forname, name, clientStreet," +
    "clientStreetNumber, clientPostcode, clientCity, kind, option, latest, ordered, charged, ok, location, processorId, executorId, employeeId, pitTypeId, serviceId," +
    "destinationId, customerPresent, emailDelivered",
  employee: "id++, uuid, valid, label, sort",
  pitType: "id++, uuid, valid, label, color",
  service:
    "id++, uuid, type, state, reference, number, scheduleFrom, scheduleTo, info, remark, date, check, longitude, latitude, longitudeRecorded, latitudeRecorded," +
    "emailRecipient, emailStatus, pitNumber, lenght, tonnage, width, pitStreet, pitStreetNumber, pitPostCode, pitCity, pin, salutation, forname, name, clientStreet," +
    "clientStreetNumber, clientPostcode, clientCity, kinds, options, processorId, executorId, employeeId, pitTypeId, drainId, timeId",
  time: "id++, uuid, valid, label, sort, customerPresent",
  filter:
    "id++, uuid, sortCriteria, sortOrder, type, state, dateFrom, dateTo, perimeter, processorPool, archive, processorId",
  vehicle: "id++, uuid, valid, label",
  parameter: "id++, uuid, key, value",
  localAttachments: "id++, blob, formId, fileName, mimeType, name, uuid, deliveredFromFlow",
  config: "id++, key, value",
  map: "id++, x, y, z, path, blob",
});

db.open().catch(function (err) {
  console.error("Failed to open db: " + (err.stack || err));
});

export const LocalAttchments = {
  addAttachment: async (newAttachment) => {
    console.log(newAttachment);
    await db.localAttachments.add({
      blob: newAttachment.blob,
      formId: newAttachment.formId,
      fileName: newAttachment.fileName,
      mimeType: newAttachment.mimeType,
      uuid: newAttachment.uuid,
      name: newAttachment.name,
      deliveredFromFlow: newAttachment.deliveredFromFlow
    });
  },
  getAttachment: async (uuid) => {
    const attachment = await db.localAttachments.where('uuid').equals(uuid).toArray();
    return attachment[0];
  },
  getByFormUuid: async (formUuid) => {
    const attachments = await db.localAttachments
      .where("formId")
      .equals(formUuid)
      .toArray();
    return attachments;
  },
  getAttachmentOtherValue: async (formUuid, columnName, value) => {
    const attachment = await db.localAttachments.where('formId').equals(formUuid).toArray();
    const filtered = attachment?.filter(x => x[columnName].includes(value));
    return filtered[0];
  },
  getAttachmentsByOtherValue: async (formUuid, columnName, value) => {
    const attachment = await db.localAttachments.where('formId').equals(formUuid).toArray();
    const filtered = attachment?.filter(x => x[columnName].includes(value));
    return filtered;
  },
  checkIfExists : async (formUuid, columnName, value) => {
    const attachments = await db.localAttachments.where('formId').equals(formUuid).toArray();
    var selectedAttachments = attachments?.filter(x => x[columnName].includes(value))
    return selectedAttachments.length > 0 ? true : false;
  },
  updateAttachment: async(uuid, changes) => {
    await db.localAttachments.where('uuid').equals(uuid).modify(changes);
    return;
  },
  removeAttachment: async(uuid) => {
    await db.localAttachments.where('uuid').equals(uuid).delete();
  },
  removeAllProtocolsByUuid: async (taskUuid) =>{
    //new method
    const list = await db.localAttachments.where("formId").equals(taskUuid).toArray();
    const protocols = list.filter(x => x.name.startsWith("Protokoll"));
    await protocols.forEach(async x => await LocalAttchments.removeAttachment(x.uuid));
  },
  removeAllCustomersProtocolsByUuid: async (taskUuid) =>{
    const list = await db.localAttachments.where("formId").equals(taskUuid).toArray();
    const protocols = list.filter(x => x.name.startsWith("Kundenbeleg"));
    await protocols.forEach(async x => await LocalAttchments.removeAttachment(x.uuid));
  },
  removeAllProtocols: async () => {
    //old method
    const list = await db.localAttachments.toArray();
    const protocols = list.filter(x => x.mimeType.includes("protocol"));
    await protocols.map(async x => {
      await LocalAttchments.removeAttachment(x.uuid)
    })
    return;
  },
  removeAllAttachmentsByForm: async(formUuid) => {
    const list =  await db.localAttachments.where({
      formId: formUuid,
      //deliveredFromFlow: false
    }).toArray();
    await list.map(async x => {
      await LocalAttchments.removeAttachment(x.uuid);
    })

    const list2 = await db.attachment.where({formUuid}).toArray();
    await list2.map(async x => {
      await db.attachment.delete(x.id);
    })
  }

};

export const Drains = {
  getAll: async () => {
    const drains = await db.drain.toArray();
    return drains;
  },
  getByUuid: async (uuid) => {
    const drain = await db.drain.where("uuid").equals(uuid).toArray();
    if (drain[0].processorId != undefined) {
      const vehicles = await db.vehicle
        .where("uuid")
        .equals(drain[0].processorId)
        .toArray();
      drain[0].processor = vehicles[0];
    }
    return drain[0];
  },
  getSingleCellByUuid: async(uuid,cellName)=>{
    const row = await Drains.getByUuid(uuid);
    return row[cellName]
  },
  updateRecord: async (uuid, changes) => {
    if(!changes.state){
      changes.state = "STARTED";
    }
    if(changes.state ==="do_not_change"){
      delete changes.state;
    }
    
    await db.drain.where("uuid").equals(uuid).modify(changes);
    return;
  },
  removeAll: async () => {
    await db.drain.clear();
  },

  removeArchived: async () => {
    const createdDrains = await db.drain.where({state: "ARCHIVED"}).toArray();
    for(var dr of createdDrains){
      await LocalAttchments.removeAllAttachmentsByForm(dr.uuid);
      await db.drain.where({uuid: dr.uuid}).delete()
    }
  }
};

export const Services = {
  getAll: async () => {
    const services = await db.service.toArray();
    return services;
  },
  getByUuid: async (uuid) => {
    const service = await db.service.where("uuid").equals(uuid).toArray();
    if (service[0].processorId) {
      const vehicles = await db.vehicle
        .where("uuid")
        .equals(service[0].processorId)
        .toArray();
      service[0].processor = vehicles[0];
    }
    return service[0];
  },
  getSingleCellByUuid: async(uuid,cellName)=>{
    const row = await Services.getByUuid(uuid);
    return row[cellName];
  },
  updateRecord: async (uuid, changes) =>{
    if(!changes.state){
      changes.state = "STARTED";
    }
    console.log(state)
    await db.service.where("uuid").equals(uuid).modify(changes);
    return;
  },
  removeAll: async () => {
    await db.service.clear();
  },
  removeArchived: async () => {
    const createdServices = await db.service.where({state: "ARCHIVED"}).toArray();
    for(var srv of createdServices){
      await LocalAttchments.removeAllAttachmentsByForm(srv.uuid);
      await db.service.where({uuid: srv.uuid}).delete()
    }
  }
};

export const Tasks = {
  getAll: async () => {
    const drains = await db.drain.toArray();
    const services = await db.service.toArray();
    const tasks = [];
    drains.forEach((x) => {
      tasks.push(x);
    });
    services.forEach((x) => {
      tasks.push(x);
    });
    return tasks;
  },
};

export const getByTableName = {
  getList: async (tableName) => {

    if(tableName == "drainOk"){
      var values = [];
      values.push({
        uuid: "1",
        label: "in Ordnung"
      });      
      values.push({
        uuid: "0",
        label: "nicht in Ordnung"
      })
      return values;
    }
    const dixieTable = await db.table(tableName);
    const table = await dixieTable.toArray();
    const tableWithoutKeyDuplicate = table.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.uuid === value.uuid
    ))
  )
    return tableWithoutKeyDuplicate;
  },
  getRow: async (tableName, uuid) => {
    console.log("tableName: " + tableName + ", uuid " + uuid)
    const row = await db.table(tableName).where('uuid').equals(uuid).toArray();
    return row[0];
  }
};

export const dbParameters = {
  getParameterByKey: async (paramName) => {
    const param = await db.parameter.where("key").equals(paramName).toArray();
    return param[0];
  },
};

export const Times = {
  getAll: async () => {
    const times = await db.time.toArray().sort((x) => x.sort);
    return times;
  },
};

export const Config = {
  get: async (key) =>{
    const conf = await db.config.where('key').equals(key).toArray();
    if(conf.length == 0){
      return null;
    }

    return conf[0].value;
  },
  addOrUpdate: async(object) => {
    if(object.key == undefined){
      console.error("Config object does not containg a key!");
    }

    let obj = await db.config.where('key').equals(object.key).toArray();
    if(obj.length > 0){
      await db.config.update(obj[0].id, object);
    }
    else{
      await db.config.add(object);
    }
  },
  checkIfExists: async (key) => {
    const cfgs = await db.config.where('key').equals(key).toArray();
    return cfgs.length > 0 ? true : false
  }
}

export const PitType = {
  getColorByUuid: async(uuid) => {
    const pit = await db.pitType.where('uuid').equals(uuid).toArray();
    return pit[0].colorAsBGR;
  }
}

export const Vehicle = {
  getByUuid: async(uuid) => {
    const vehicle = await db.vehicle.where('uuid').equals(uuid).toArray();
    return vehicle[0].label;
  },
  getList: async() => {
    return await db.vehicle.toArray();
  }
}

export const Map =  {
  addTile: async(tile) => {
    await db.map.add({
      x: tile.x,
      y: tile.y,
      z: tile.z,
      path: tile.path,
      blob: tile.blob
    });
    return;
  },
  getTile: async(z,x,y) => {
    const tile = await db.map.where(
      {
        z: z,
        x: x,
        y: y
      }
    ).toArray();
    console.log(tile[0])
    return tile[0];
  }
}

export const Destination = {
  getList: async () => {
    return await db.destination.toArray();
  }
}

export const Employee = {
    getList: async() => {
      return await db.employee.toArray();
    }
}
