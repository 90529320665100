import jsPDF from "jspdf";
import { Drains, getByTableName, LocalAttchments } from "../db";
import ConfirmationPdfLayout from "./ConfirmationPDFLayout";
import Tools from "../Tools";
import Parameters from "../Parameters";

export const PdfDrainConfrimation = {
  createPdf: async (uuid) => {
    var vehicleName = "";
    var driverName = "";
    var kind = "";
    var option = "";
    var signature = null;

    var doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const objectWidth = pageWidth - 20;

    const task = await Drains.getByUuid(uuid);

    if (task.executorId) {
      const vehicle = await getByTableName.getRow("vehicle", task.executorId);
      vehicleName = vehicle.label;
    } else {
      vehicleName = task.processor.label;
    }

    if (task.employeeId) {
      const driver = await getByTableName.getRow("employee", task.employeeId);
      driverName = driver.label;
    }

    if (task.kind) {
      kind = await Parameters.findValueByKey(task.kind);
    }

    if (task.option) {
      const selectedOption = await getByTableName.getRow(
        "parameter",
        task.option
      );
      option = selectedOption.value;
    }

    if (await LocalAttchments.checkIfExists(uuid, "mimeType", "signature")) {
      const attachment = await LocalAttchments.getAttachmentOtherValue(
        uuid,
        "mimeType",
        "signature"
      );
      signature = attachment.blob;
    }

    const companyAddressHeight = 55;
    doc = await ConfirmationPdfLayout.getHeader(
      doc,
      companyAddressHeight,
      objectWidth
    );

    const addressHeight = companyAddressHeight + 12;

    doc.setFontSize(10);
    doc.text(task.salutation, 20, addressHeight); //salutation
    doc.text(
      (task.forname.length > 0 ? task.forname + " " : "") + task.name,
      20,
      addressHeight + 5
    ); // Name
    doc.text(
      task.clientStreet + " " + task.clientStreetNumber,
      20,
      addressHeight + 10
    ); // street
    doc.text(
      (task.clientPostCode ?? "") + " " + (task.clientCity ?? ""),
      20,
      addressHeight + 15
    );

    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    const titleHeight = 120;
    doc.text("Kundenbeleg Entleerungsauftrag", 20, titleHeight);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");

    const titleColumWidth = 100;
    const valueColumWidth = 110;
    let kundenNrHeight = titleHeight + 10;

    doc.text("Kundennummer:", titleColumWidth, kundenNrHeight, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.pin, valueColumWidth, kundenNrHeight);

    doc.setFont("helvetica", "bold");
    doc.text("Auftragsnummer:", titleColumWidth, kundenNrHeight + 5, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.number, valueColumWidth, kundenNrHeight + 5);

    doc.setFont("helvetica", "bold");
    doc.text("Entsorgungsort:", titleColumWidth, kundenNrHeight + 10, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(
      task.pitStreet + " " + task.pitStreetNumber,
      valueColumWidth,
      kundenNrHeight + 10
    );
    doc.text(
      (task.pitPostCode ? task.pitPostCode + " " : "") + (task.pitCity ?? ""),
      valueColumWidth,
      kundenNrHeight + 15
    );

    doc.setFont("helvetica", "bold");
    doc.text("Anlagennummer:", titleColumWidth, kundenNrHeight + 20, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.pitNumber, valueColumWidth, kundenNrHeight + 20);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Abfuhrmenge:", titleColumWidth, kundenNrHeight + 30, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(
      task.charged ? task.charged + "m³" : "",
      valueColumWidth,
      kundenNrHeight + 30
    );
    doc.setFontSize(8);
    // if (task.charged) {
    //   doc.text(
    //     "3",
    //     valueColumWidth + doc.getTextWidth(task.charged + "m"),
    //     kundenNrHeight + 28
    //   );
    // }
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("Tarif:", titleColumWidth, kundenNrHeight + 40, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(kind, valueColumWidth, kundenNrHeight + 40);

    // doc.setFont("helvetica", "bold");
    // doc.text("Zuschläge:", titleColumWidth, kundenNrHeight + 45, {
    //   align: "right",
    // });

    // doc.setFont("helvetica", "normal");
    // doc.text(option, valueColumWidth, kundenNrHeight + 45);

    doc.setFont("helvetica", "bold");
    doc.text("Bemerkungen:", titleColumWidth, kundenNrHeight + 45, {
      align: "right",
    });

    const remarkSplitted = doc.splitTextToSize(task.remark, 100);
    console.log(remarkSplitted);
    doc.setFont("helvetica", "normal");
    let remarkStartHeight = kundenNrHeight + 45;
    if (task.remark && task.remark.length > 0) {
      for (let i = 0; i < remarkSplitted.length; i++) {
        doc.text(remarkSplitted[i] ?? "", valueColumWidth, remarkStartHeight);
        remarkStartHeight += 5;
      }
    } else {
      doc.text("-", valueColumWidth, remarkStartHeight);
    }

    kundenNrHeight = kundenNrHeight + remarkSplitted.length * 5;

    doc.setFont("helvetica", "bold");
    doc.text("Schlauchlänge:", titleColumWidth, kundenNrHeight + 50, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(
      task.length ? task.length + "m" : "-",
      valueColumWidth,
      kundenNrHeight + 50
    );

    doc.setFont("helvetica", "bold");
    doc.text("Fahrzeug:", titleColumWidth, kundenNrHeight + 55, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(vehicleName, valueColumWidth, kundenNrHeight + 55);

    doc.setFont("helvetica", "bold");
    doc.text("Fahrer:", titleColumWidth, kundenNrHeight + 60, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(driverName, valueColumWidth, kundenNrHeight + 60);

    doc.setFont("helvetica", "bold");
    doc.text("Datum:", titleColumWidth, kundenNrHeight + 70, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(getDate(task), valueColumWidth, kundenNrHeight + 70);

    doc.setFont("helvetica", "bold");
    doc.text("Kundenunterschrift:", titleColumWidth, kundenNrHeight + 80, {
      align: "right",
    });

    if (signature != null) {
      const signatureImg = await Tools.convertBlobToBase64(signature);
      const signatureWidth = 80;
      const signatureImgProps = doc.getImageProperties(signatureImg);
      const aspectRatio = signatureImgProps.width / signatureImgProps.height;
      const signatureHeight = signatureWidth / aspectRatio;

      doc.addImage(
        signatureImg,
        "PNG",
        valueColumWidth,
        kundenNrHeight + 85,
        signatureWidth,
        signatureHeight
      );
    } else {
      doc.setFont("helvetica", "normal");
      if (task.customerPresent) {
        doc.text("Kunde anwesend", valueColumWidth, kundenNrHeight + 90);
      } else {
        doc.text("Kunde nicht anwesend", valueColumWidth, kundenNrHeight + 90);
      }
    }

    //footer

    doc = ConfirmationPdfLayout.getFooter(doc);

    //Legal information page
    if(task.emailRecipient != undefined && task.emailRecipient.length > 5){
      doc.addPage();
      // let pageHeight = 0;
      doc = await ConfirmationPdfLayout.getOnlyLogo(doc, objectWidth);
  
      let legalInformationText =
        "Ich willige ein, dass mir der Beleg zur Abfuhr von Sammelgruben und Kleinkläranlagen, die auch personenbezogene Daten im Sinne der EU-Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG) enthalten, an meinen nachfolgende E-Mail-Adresse übersandt werden ([email_address]). Ich versichere, auf diese E-Mail-Adresse ungehindert vollen Zugriff zu haben. Ich werde unverzüglich darüber informieren, falls sich dies ändert oder ich eine andere Adresse nutzen sollte.";
      legalInformationText = legalInformationText.replace(
        "[email_address]",
        task.emailRecipient
      );
      doc.setTextColor("black");
      doc.setFontSize("12");
      doc.text(legalInformationText, 15, 80, { maxWidth: 180, align: "justify" });
      const legalInformationP2 =
        "Bitte achten Sie darauf, keinen Dritten Zugriff auf Ihre E-Mails zu gewähren. Daher sollten Sie insbesondere keine E-Mail-Adresse verwenden, die von anderen empfangen wird oder die Sie gemeinsam mit anderen, etwa Familienmitgliedern gemeinsam benutzen. Ihnen ist bekannt, dass zwar der Versand an den Mailserver verschlüsselt erfolgt („Transportverschlüsselung“), der Inhalt der E-Mails jedoch grundsätzlich unverschlüsselt ist und mit der Datenübertragung über das Internet erhebliche Sicherheitsrisiken verbunden sind und daher nicht ausgeschlossen werden kann, dass sich andere Personen Zugriff auf den Inhalt unverschlüsselter E-Mails verschaffen.";
      doc.text(legalInformationP2, 15, 115, {maxWidth: 180, align: "justify"})
      const legalInformationP3 =
        "Sie haben jederzeit die Möglichkeit, die Speicherung ihrer eMail-Adresse zu widerrufen. Hierzu senden Sie uns einfach eine eMail an info@azv-sude-schaale.de.";
      doc.text(legalInformationP3, 15, 160, {maxWidth: 180, align: "justify"})
        const legalInformationP4 =
        "Unsere vollständige Datenschutzerklärung finden Sie unter https://www.azv-sude-schaale.de/datenschutz.";
      doc.text(legalInformationP4, 15, 175, {maxWidth: 180, align: "left"})
  
    const consents = await LocalAttchments.getAttachmentsByOtherValue(task.uuid,"mimeType","consent");
    if(consents && consents.length > 0){
      const consentsNotFromFlow = consents.filter((x) => {return !x.deliveredFromFlow});
      if(consentsNotFromFlow.length > 0){
        const base64consent = await blobToBase64(await consentsNotFromFlow[0].blob)
        doc.addImage(base64consent, "PNG", 120,190,60,30)
      }
      else{
        // const consent = consents.filter((x) => {return !x.deliveredFromFlow});
        const base64consent = await blobToBase64(await consents[0].blob);
        doc.addImage(base64consent, "PNG", 120,190,60,30);
      }
    }
    doc = ConfirmationPdfLayout.getFooter(doc);
    }

    var blobPDF = new Blob([doc.output("blob")], { type: "application/pdf" });
    return blobPDF;
  },
};
const getDate = (task) => {
  const date = Tools.timeStampToDate(task.date);

  const fixLeadingZero = (val) => {
    if (val < 10) {
      return `0${val}`;
    } else {
      return `${val}`;
    }
  };
  return `${fixLeadingZero(date.getDate())}.${fixLeadingZero(
    date.getMonth() + 1
  )}.${date.getFullYear()} ${fixLeadingZero(date.getHours())}:${fixLeadingZero(
    date.getMinutes()
  )}`;
};

const blobToBase64 = (blob) =>  {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}