import { t } from "i18next";
import { toast } from "react-toastify";
import { Drains, LocalAttchments, Services } from "../../components/db";
import FieldConfig from "../../components/FieldConfig";
import Tools from "../../components/Tools";

const ValidateAndComplete = {
  Drain: async (uuid) => {
    const drain = await Drains.getByUuid(uuid);
    if (!drain) {
      toast.error("Error in validation");
    }

    var errors = [];

    const location = await FieldConfig("location");
    let locationMandatory = false;
    if(drain.location_mandatory != undefined){
      locationMandatory = drain.locationMandatory
    }
    else{
      locationMandatory = location.mandatory
    }
    if(!drain.location && locationMandatory){
      errors.push(t("drain.location"))
    }

    const widthField = await FieldConfig("width");
    if (!drain.width && widthField.mandatory) {
      errors.push(t("drain.width"));
    }

    const tonnage = await FieldConfig("tonnage")
    if (!drain.tonnage && tonnage.mandatory) {
      errors.push(t("drain.tonnage"));
    }

    const length = await FieldConfig("length")
    let lengthMandatory = false;
    if(drain.length_mandatory != undefined){
      lengthMandatory = drain.length_mandatory
    }
    else{
      lengthMandatory = length.mandatory
    }
    if ((!drain.length || drain.length < 1) && lengthMandatory) {
      errors.push(t("drain.length"));
    }

    const pitTypeId = await FieldConfig("pitTypeId");
    if(!drain.pitTypeId && pitTypeId.mandatory){
      errors.push(t('drain.pitType'))
    }

    const kind = await FieldConfig("kind");
    let kindMandatory = false;
    if(drain.kind_mandatory != undefined){
      kindMandatory = drain.kind_mandatory
    }
    else{
      kindMandatory = kind.mandatory;
    }
    if (!drain.kind && kindMandatory) {
      errors.push(t("drain.kind"));
    }

    const option = await FieldConfig("option");
    if(!drain.option && option.mandatory){
      errors.push(t("drain.option"))
    }

    const destinationId = await FieldConfig("destinationId");
    let destinationIdMandatory = false;
    if(drain.destinationId_mandatory != undefined){
      destinationIdMandatory = drain.destinationId_mandatory
    }
    else{
      destinationIdMandatory = destinationId.mandatory
    }
    if (!drain.destinationId && destinationIdMandatory) {
      errors.push(t("drain.destination"));
    }

    const latest = await FieldConfig("latest");
    if (!drain.latest && latest.mandatory) {
      errors.push(t('drain.latest'));
    }

    const ordered = await FieldConfig("ordered");
    if (!drain.destinationId && ordered.mandatory) {
      errors.push(t('drain.ordered'));
    }

    const charged = await FieldConfig("charged");
    let chargedMandatory = false;
    if(drain.charged_mandatory != undefined){
      chargedMandatory = drain.charged_mandatory
    }
    else{
      chargedMandatory = charged.mandatory
    }

    if ((!drain.charged | drain.charged < 1) && chargedMandatory) {
      errors.push(t("drain.charged"));
    }

    const ok = await FieldConfig("ok")
    console.log(ok);
    if (drain.ok == undefined && ok.mandatory) {
      errors.push(t("drain.ok"));
    }

    const remark = await FieldConfig("remark");
    let remarkMandatory = false;
    if(drain.remark_mandatory != undefined){
      remarkMandatory = drain.remark_mandatory;
    }
    else{
      remarkMandatory = remark.mandatory
    }
    if (!drain.remark && remarkMandatory) {
      errors.push(t('drain.remark'));
    }

    const executorId = await FieldConfig("executorId");
    if (!drain.executorId && executorId.mandatory) {
      errors.push(t("drain.car"));
    }

    const employeeId = await FieldConfig("employeeId");
    if (!drain.employeeId && employeeId.mandatory) {
      errors.push(t("drain.driver"));
    }

    if(drain.kind && drain.kind === "CG_KN_15"){
      console.log(drain.charged)
      if(drain.charged !== null && drain.charged > 0){
        errors.push('bei „Leerfahrt“ muss die Abfuhrmenge „0“ sein')
      }
    }

    if (errors.length > 0) {
      toast.error(<GenerateErrorMessage msg={errors} />, { onClose: () => {} });
      return false;
    }

    const date = new Date();
    const timeStamp = Tools.dateToTimeStamp(date);
    await Drains.updateRecord(uuid, { state: "COMPLETED", date: timeStamp });
    await LocalAttchments.removeAllProtocolsByUuid(uuid);

    return true;
  },



  Service: async (uuid) => {
    const service = await Services.getByUuid(uuid);
    if (!service) {
      toast.error("Error in validation");
    }

    var errors = [];

    const timeId = await FieldConfig("timeId");
    if (!service.timeId && timeId.mandatory) {
      errors.push("Bearbeitungszeit");
    }

    const executorId = await FieldConfig("executorId");
    if (!service.executorId && executorId.mandatory) {
      errors.push("Fahzeug");
    }

    const employeeId = await FieldConfig("employeeId");
    if (!service.employeeId && employeeId) {
      errors.push("Fahrer");
    }

    if (errors.length > 0) {
      toast.error(<GenerateErrorMessage msg={errors} />);
      return;
    }

    const date = new Date();
    const timeStamp = Tools.dateToTimeStamp(date);

    await Services.updateRecord(uuid, { state: "COMPLETED", date: timeStamp });

    try{
      // const pdfProtocol = await PdfDrainConfrimation.createPdf(uuid);
      // console.log(pdfProtocol);
      // await generateProtocol(pdfProtocol,uuid);
    }
    catch(err){
      console.warn(err);
    }
    // toast.message("Task marked as Completed!", {
    //   onClose: () => (window.location.href = "/"),
    // });
    return;
  },
};

export default ValidateAndComplete;

export const GenerateErrorMessage = (props) => {
  return (
    <>
      <p>{t("validation.fillRequiredFields")}</p>
      <ul>
        {props.msg.map((element, index) => {
          return <li key={index}>{element}</li>;
        })}
      </ul>
    </>
  );
};
